@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@300;400;500;600;700;800;900&family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  font-size: 14px;
}

span,
i,
b,
strong,
div {
  font-size: inherit;
}

a {
  font: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* MAP MONITORING CSS */
.location-fade-enter {
  transform: translateY(120px);
  opacity: 0;
}

.location-fade-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 300ms ease-in;
}

.location-fade-exit {
  opacity: 1;
  transform: translateY(0px);
}

.location-fade-exit-active {
  opacity: 0;
  transform: translateY(120px);
  transition: all 300ms ease-out;
}

/* Tasks Add New Comment Transition */
.new-comment-enter {
  opacity: 0.01;
  transform: scale(0.95);
}

.new-comment-enter-active {
  opacity: 1;
  transform: scale(1);
  transition:
    opacity 500ms,
    transform 500ms;
}

.new-comment-exit {
  opacity: 1;
  transform: scale(1);
}

.new-comment-exit-active {
  opacity: 0.01;
  transform: scale(0.95);
  transition:
    opacity 500ms,
    transform 500ms;
}

/* INVENTORY VEHICLE & PARTS TAB */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Error 404 Animation Of Logo */
@keyframes burst-animation {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(2); /* Adjust scaling as needed */
    opacity: 0;
  }
}

/* NOTIFICATIONS ADDITION AND REMOVAL TRANSITION */
.notification-slide-enter {
  transform: translateX(-100%);
}
.notification-slide-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.notification-slide-exit {
  transform: translateX(0%);
}
.notification-slide-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}
/* Vehicle Animation For Drawer [Vehicle History Log] */
@keyframes moveUp {
  0% {
    transform: translateY(40vh);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.addAlert {
  transition: 0.5s;
  transform: translate3d(1000px, 0, 0);
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-delay: 200ms;
  backface-visibility: hidden;
  perspective: 1000px;
}

.removeAlert {
  transition: 0.5s;
  animation: un_shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-delay: 200ms;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.alertDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 4px;
}

.iconContainerStyle {
  min-height: 60px;
  min-width: 60px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

@keyframes un_shake {
  20% {
    transform: translate3d(16px, 0, 0);
  }
  40% {
    transform: translate3d(-16px, 0, 0);
  }
  100% {
    transform: translate3d(1000px, 0, 0);
  }
}

@keyframes shake {
  30% {
    transform: translate3d(0, 0, 0);
  }

  70% {
    transform: translate3d(8px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(-16px, 0, 0);
  }

  50% {
    transform: translate3d(16px, 0, 0);
  }
  60% {
    transform: translate3d(-8px, 0, 0);
  }
}

@keyframes closeEaseTransition {
  100% {
    width: 0%;
  }
}
